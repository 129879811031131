<template>
  <th-wrapper
    :title="$t('pages.settings.api_keys.title')"
    :subtitle="$t('pages.settings.api_keys.subtitle')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <!-- If multiple pairings -->
      <el-form-item v-if="multipleEntities" prop="entities" class="mb-0">
        <el-select
          v-model="entities"
          v-cancel-read-only
          filterable
          clearable
          :placeholder="$t('common.interactions.selects.placeholder')"
        >
          <el-option
            v-for="item in entities"
            :key="item.id"
            :label="item.name || item.branch_number || '--'"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </th-wrapper>
</template>
