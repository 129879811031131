<template>
  <section>
    <api-keys-header ref="api-keys-header" />
    <th-datatable
      ref="table"
      do-route
      do-route-filters
      no-meta-check
      show-filter
      prune-search-filters
      sortable
      sort-type="remote"
      resource="apiKeys"
      route-base="/settings/dashboard/api_keys"
      :headers="headers"
      :show-operations="false"
      :search-filters="filtersList"
      @loading-error="handleLoadingError"
    />
  </section>
</template>
<script>
import ApiKeysHeader from './components/api-keys-header'

export default {
  components: {
    ApiKeysHeader
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.customers.title')
    }
  },
  computed: {
    headers() {
      return [
        {
          field: 'alias',
          label: this.$t('pages.settings.api_keys.table.alias'),
          minWidth: 120,
          truncate: true,
          fallback: '-'
        },
        {
          field: 'salesChannel',
          label: this.$t('pages.settings.api_keys.table.salesChannel'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'publicKey',
          label: this.$t('pages.settings.api_keys.table.publicKey'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        },
        {
          field: 'privateKey',
          label: this.$t('pages.settings.api_keys.table.privateKey'),
          fallback: '-',
          minWidth: 170,
          truncate: true
        }
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
    filtersList() {
      //todo: to be defined
    }
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.settings.customers.title')
        })
      })
    }
  }
}
</script>
